import React, { useEffect } from "react";

const AdSenseComponent = () => {
  useEffect(() => {
    // Ensure the adsbygoogle script runs
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense error:", e);
    }
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "20px 0" }}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6853618643249561"
        data-ad-slot="4643165665"
        data-ad-format="autorelaxed"
      ></ins>
    </div>
  );
};

export default AdSenseComponent;
