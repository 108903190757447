import {
  Button,
  CircularProgress,
  Fab,
  Pagination,
  Popover,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  AddLink,
  ContentCopy,
  NewReleases,
  Whatshot,
} from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import LinkBox from "./LinkBox";
import Shortify from "./shortify";
import PostLink from "./postLink";
import { generateRandomCode } from "./Utility";
import axios from "axios";
import Footer from "./Footer";
import AdSenseComponent from "./MultiplexAd";

const baseURL = "https://server.ishowlink.site";
// const baseURL = "http://localhost:5000";
//
const Home = () => {
  const categories = [
    // Business and Commerce
    "Ecommerce",
    "Corporate",
    "Marketplace",
    "RealEstate",
    "Finance",
    "Affiliate",
    "SaaS",
    "Crowdfunding",
    "Auction",
    "Jobs",
    "Directory",
    "Booking",

    // Personal and Professional
    "Portfolio",
    "Resume",
    "Blog",
    "Creative",
    "Photography",
    "Personal",

    // Entertainment and Media
    "Movies",
    "Music",
    "Video",
    "Gaming",
    "Entertainment",
    "News",
    "Sports",
    "Fashion",

    // Educational and Informative
    "Educational",
    "Courses",
    "Wiki",
    "Science",
    "Health",
    "Fitness",
    "Food",
    "Travel",
    "Parenting",
    "Lifestyle",

    // Social and Community
    "Social",
    "Community",
    "Forum",
    "Dating",
    "Events",
    "Pets",
    "Hobby",

    // Technology and Innovation
    "Technology",
    "Cryptocurrency",
    "Productivity",
    "Support",
    "Startup",
    "TechDemo",

    // Non-Profit and Charitable
    "Charity",
    "Donation",

    // Specialized and Niche
    "Comparison",
    "Survey",
    "Membership",
    "Landing",
    "Fashion",
    "CreativeTools",
    "AdPlatform",
    "ArtShowcase",
  ];
  // const darkmode = new Darkmode();
  const [theme, setTheme] = useState("light");

  // const handleDarkMode = () => {
  //   darkmode.toggle();
  //   setTheme(theme === "light" ? "dark" : "light");
  // };

  const [value, setValue] = useState(1);
  const [popvalue, setPopvalue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePopTabChange = (event, newvalue) => {
    setPopvalue(newvalue);
  };

  // Create a theme object dynamically based on dark or light mode
  const currentTheme = createTheme({
    palette: {
      mode: theme,
      primary: {
        main: theme === "light" ? "#1976d2" : "#90caf9",
      },
      background: {
        default: theme === "light" ? "#fff" : "#121212",
      },
      text: {
        primary: theme === "light" ? "#000" : "#fff",
      },
    },
  });

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function CustomPopTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ height: "300px" }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const fabRef = useRef(null);

  useEffect(() => {
    // Set anchorEl to Fab's ref and open Popover when page loads
    if (fabRef.current) {
      setAnchorEl(fabRef.current);
      setOpen(true);
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setShortLink("");
    setShowShortLink(false);
  };

  const [open, setOpen] = useState(true);
  const id = open ? "simple-popover" : undefined;

  const [trendingLinks, setTrendingLinks] = useState(null);
  const [newLinks, setNewLinks] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(null);

  const [currentTrendingPage, setCurrentTrendingPage] = useState(1);

  const handlePageChangeTrending = (event, page) => {
    console.log("Selected Page:", page);
    setCurrentTrendingPage(page); // Update the current page in the state
  };

  const handlePageChangeNew = (event, page) => {
    console.log("Selected Page:", page);
    setPage(page); // Update the current page in the state
  };

  useEffect(() => {
    axios.get(`${baseURL}/totalPosts`).then((res) => {
      console.log(res["data"]);
      setTotalPosts(res["data"]);
    });

    setTrendingLinks(null);
    setNewLinks(null);

    if (value === 1) {
      axios
        .get(`${baseURL}/getTrendingPosts/${currentTrendingPage}`)
        .then((res) => {
          console.log(res["data"]);
          setTrendingLinks(res["data"]);
        });
    }

    if (value === 2) {
      axios.get(`${baseURL}/getNewPosts/${page}`).then((res) => {
        console.log(res["data"]);
        setNewLinks(res["data"]);
      });
    }
  }, [value, page, currentTrendingPage]);

  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const [loadingAddLink, setloadingAddLink] = useState(false);

  const [showShortLink, setShowShortLink] = useState(false);

  const [shortLink, setShortLink] = useState("");

  const handlePostLink = () => {
    const linkURL = document.getElementById("postLinkURL").value;
    console.log(linkURL);
    const linkDescription = document.getElementById("linkDescription").value;
    console.log(linkDescription);
    const linkCategory = document.getElementById("categorySelected").value;
    console.log(linkCategory);

    const linkClickCount = 0;

    if (linkURL === "") {
      alert("Link cannot be empty");
      return;
    }
    if (isValidURL(linkURL) === false) {
      alert("Please add valid URL link");
      return;
    }

    const linkId = generateRandomCode(8);

    const randomCode = generateRandomCode(6);
    const shortLinkTemp = window.origin + "/" + randomCode;

    setloadingAddLink(true);
    axios
      .post(`${baseURL}/addlink`, {
        linkId: randomCode,
        link: linkURL,
        text: linkDescription,
        category: linkCategory,
        count: linkClickCount,
      })
      .then((res) => {
        console.log(res["data"]);
        setNewLinks([
          {
            linkId: randomCode,
            link: linkURL,
            text: linkDescription,
            category: linkCategory,
            count: linkClickCount,
          },
          ...trendingLinks,
        ]);
        setShowShortLink(true);
        setShortLink(shortLinkTemp);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        console.log("done");
        setloadingAddLink(false);
      });
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <div className="App">
        <h1
          style={{
            color: "#1976d2",
            fontWeight: "bolder",
            fontFamily: "monospace",
            cursor: "pointer",
          }}
        >
          I SHOW{" "}
          <img src="/icon.jpg" alt="logo" width="30px" height="30px"></img> LINK
        </h1>
        <div>
          {/* <Button onClick={handleDarkMode}>🌓</Button> */}
          {/* <Button variant="contained" style={{ fontWeight: "bold" }}>
              Shortify Link
            </Button> */}
        </div>
        <div
          className="content-box"
          style={{ height: "75vh", marginTop: "60px" }}
        >
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example"
              centered
            >
              <Tab
                icon={<Whatshot />}
                iconPosition="start"
                value={1}
                label="Trending"
                sx={{ fontWeight: "bold" }}
              ></Tab>
              <Tab
                icon={<NewReleases />}
                iconPosition="start"
                value={2}
                label="New"
                sx={{
                  fontWeight: "bold",
                  // color: theme === "dark" ? "white" : "black",
                }}
              ></Tab>
            </Tabs>
          </div>
          <div>
            <CustomTabPanel value={value} index={1}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {totalPosts ? (
                  <Pagination
                    count={parseInt(totalPosts / 10 + 1)}
                    page={currentTrendingPage}
                    onChange={handlePageChangeTrending}
                    color="primary"
                  />
                ) : null}
              </div>
              <div style={{ height: "70vh", overflow: "scroll" }}>
                {trendingLinks ? (
                  trendingLinks.map((item, idx) => {
                    if (idx === 9) {
                      return (
                        <div>
                          <AdSenseComponent></AdSenseComponent>
                          <LinkBox
                            key={idx}
                            text={item["text"]}
                            link={item["link"]}
                            category={item["category"]}
                            count={item["count"]}
                            linkId={item["linkId"]}
                          ></LinkBox>
                        </div>
                      );
                    } else
                      return (
                        <LinkBox
                          key={idx}
                          text={item["text"]}
                          link={item["link"]}
                          category={item["category"]}
                          count={item["count"]}
                          linkId={item["linkId"]}
                        ></LinkBox>
                      );
                  })
                ) : (
                  <CircularProgress></CircularProgress>
                )}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {totalPosts ? (
                  <Pagination
                    count={parseInt(totalPosts / 10 + 1)}
                    page={page}
                    onChange={handlePageChangeNew}
                    color="primary"
                  />
                ) : null}
              </div>
              <div style={{ height: "70vh", overflow: "scroll" }}>
                {newLinks ? (
                  newLinks.map((item, idx) => {
                    if (idx === 9) {
                      return (
                        <div>
                          <AdSenseComponent></AdSenseComponent>
                          <LinkBox
                            key={idx}
                            text={item["text"]}
                            link={item["link"]}
                            category={item["category"]}
                            count={item["count"]}
                            linkId={item["linkId"]}
                          ></LinkBox>
                        </div>
                      );
                    } else
                      return (
                        <LinkBox
                          key={idx}
                          text={item["text"]}
                          link={item["link"]}
                          category={item["category"]}
                          count={item["count"]}
                          linkId={item["linkId"]}
                        ></LinkBox>
                      );
                  })
                ) : (
                  <CircularProgress></CircularProgress>
                )}
              </div>
            </CustomTabPanel>
          </div>
        </div>
        <Fab
          ref={fabRef}
          color="primary"
          aria-label="add"
          className="fab-button"
          onClick={handleClick}
        >
          <AddLink></AddLink>
        </Fab>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: window.innerWidth >= 600 ? "right" : "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: window.innerWidth >= 600 ? "left" : "right",
          }}
        >
          <Tabs
            value={popvalue}
            onChange={handlePopTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
            centered
          >
            <Tab
              // icon={<Whatshot />}
              // iconPosition="start"
              value={1}
              label="Shortify link"
              sx={{ fontWeight: "bold" }}
            ></Tab>
            {/* <Tab
                // icon={<NewReleases />}
                // iconPosition="start"
                value={2}
                label="Shortify link"
                sx={{
                  fontWeight: "bold",
                  // color: theme === "dark" ? "white" : "black",
                }}
              ></Tab> */}
          </Tabs>
          <CustomPopTabPanel value={popvalue} index={1}>
            <Box
              sx={{ p: 2 }}
              style={{
                width: "260px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <Typography sx={{ fontWeight: "bold" }}>Shortify Link</Typography> */}
              <span>Description</span>
              <textarea id="linkDescription"></textarea>
              <select
                style={{
                  margin: "10px 0px",
                  width: "100px",
                  fontSize: "14px",
                }}
                id="categorySelected"
              >
                <option>Uncategorized</option>
                {categories.map((item) => {
                  return <option>{item}</option>;
                })}
              </select>
              <span>Link</span>
              <textarea id="postLinkURL"></textarea>
              {!loadingAddLink ? (
                <Button
                  variant="contained"
                  sx={{ fontWeight: "bold", mt: 2, width: "100px" }}
                  onClick={handlePostLink}
                >
                  Shortify
                </Button>
              ) : (
                <CircularProgress></CircularProgress>
              )}
              {showShortLink ? (
                <div style={{ marginBottom: "30px" }}>
                  <span>Your short link here 👇</span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a href={shortLink} target="_blank" rel="noreferrer">
                      {shortLink}
                    </a>
                    <ContentCopy sx={{ ml: "10px" }}></ContentCopy>
                  </div>
                </div>
              ) : null}
            </Box>
          </CustomPopTabPanel>
          {/* <CustomPopTabPanel value={popvalue} index={2}>
              <Shortify></Shortify>
            </CustomPopTabPanel> */}
        </Popover>
        <Footer></Footer>
      </div>
    </ThemeProvider>
  );
};

export default Home;
