import { Reply, Share } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getLinkPreview } from "link-preview-js";

const LinkBox = ({
  text = "Link text",
  link = window.origin,
  count = Math.floor(Math.random() * 250),
  category = "Anime",
  linkId = "",
}) => {
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    getLinkPreview(link)
      .then((data) => setPreviewData(data))
      .catch((err) => console.error("Error fetching link preview:", err));
  }, [link]);

  //   if (!previewData) {
  //     return <p>Loading preview...</p>;
  //   }

  return (
    <div
      style={{
        width: window.innerWidth >= 600 ? "30vw" : "80vw",
        margin: "10px",
        padding: "20px",
        border: "2px solid lightgray",
        borderRadius: "20px",
      }}
    >
      <div
        style={{
          marginBottom: "5px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ textAlign: "start" }}>{text}</div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            padding: "5px 8px",
            border: "3px solid lightgray",
            backgroundColor: "lightgray",
            borderRadius: "20px",
            maxHeight: "30px",
          }}
        >
          {category}
        </div>
      </div>
      <div style={{ textAlign: "start", fontWeight: "bold" }}>
        <a href={window.origin + "/" + linkId} target="_blank" rel="noreferrer">
          {window.origin + "/" + linkId}
          {previewData ? (
            <div>
              <img
                src={previewData.images[0]}
                alt={previewData.title}
                style={{ width: "100%" }}
              />
              <h3>{previewData.title}</h3>
            </div>
          ) : null}
        </a>
      </div>
      <div
        style={{
          textAlign: "end",
          display: "flex",
          justifyContent: "end",
          alignContent: "center",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        <span style={{ fontSize: "12px" }}>{count}</span>
        <Reply fontSize="10" style={{ fontWeight: "bolder" }}></Reply>
      </div>
    </div>
  );
};

export default LinkBox;
