import React from "react";
import { Container, Typography, Box } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>

      <Typography paragraph>
        The terms of service establish the rules for accessing and using the ishowlink.site, including any content functionality and services offered by the site, whether as a guest or registered user.
      </Typography>

      <Typography paragraph>
        Be sure to read the terms of service carefully before using the site. This page explains the terms of use of ishowlink.site, and by using the site, you agree to these terms of use.
      </Typography>

      <Typography paragraph>
        ishowlink.site is a service that transforms the link from a site, blog, forum, or social network into a shortened link to be shared elsewhere.
      </Typography>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Conditions of Use
        </Typography>
        <Typography paragraph>
          In order to provide a free service, it is necessary to agree to the following conditions of use when using our services:
        </Typography>
        <ul>
          <li>Short URLs that do not have at least one click per month are disabled.</li>
          <li>Short URLs that have been disabled may become available for use by other users.</li>
        </ul>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Prohibited Content
        </Typography>
        <Typography paragraph>
          ishowlink.site is available for users of all countries and of any age. Therefore, it is not allowed to create shortened URLs that redirect to:
        </Typography>
        <ul>
          <li>Pop-ups, scripts, and malicious code.</li>
          <li>Double redirection.</li>
        </ul>
      </Box>

      <Typography paragraph>
        Any shortened URL that leads to a landing page that fits the above conditions may be deleted. All URLs created are analyzed by our team. If we receive a report of spam or notice any kind of abusive behavior against our terms of service, the shortened URL will be deleted without notice.
      </Typography>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Updates or Corrections
        </Typography>
        <Typography paragraph>
          If you want to request an update or correction of a shortened URL that you created, access our contact form, providing the long URL and the short URL. Our team will check if it is possible to change your shortened URL.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
