import React from "react";
import { Box, Typography, Link, Container, Grid2 } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1a1a1a" : "#f8f8f8",
        borderTop: "1px solid #ddd",
      }}
    >
      <Container maxWidth="lg">
        <Grid2 container spacing={2} alignItems="center" justifyContent="center">
          {/* Logo or Website Name */}
          <Grid2 item xs={12} sm={6} textAlign="center">
            <Typography variant="body1" color="textSecondary">
              © {new Date().getFullYear()}{" "}
              <Link
                href="https://ishowlink.site"
                underline="hover"
                color="inherit"
              >
                ishowlink.site
              </Link>
            </Typography>
          </Grid2>

          {/* Links Section */}
          <Grid2 item xs={12} sm={6} textAlign="center">
            <Box>
              <Link
                href="/privacy-policy"
                underline="hover"
                sx={{ mx: 1, fontSize: "0.875rem" }}
                color="inherit"
              >
                Privacy Policy
              </Link>
              <Link
                href="/terms-of-service"
                underline="hover"
                sx={{ mx: 1, fontSize: "0.875rem" }}
                color="inherit"
              >
                Terms of Service
              </Link>
              {/* <Link
                href="/contact-us"
                underline="hover"
                sx={{ mx: 1, fontSize: "0.875rem" }}
                color="inherit"
              >
                Contact Us
              </Link> */}
            </Box>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default Footer;
