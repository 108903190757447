import React from "react";
import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography paragraph>
        The privacy policy explains what personal information we collect and how it is used. ishowlink.site collects this information to analyze and provide better experiences with our services. If you do not want to agree to the Privacy Policy, you must not access or use the site.
      </Typography>

      {/* <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Information Collected
        </Typography>
        <Typography paragraph>
          ishowlink.site may collect personal information, such as your email address, when you use the contact form or sign up for our services. In addition, we collect information about your use of our services, including your IP address, web browser, and operating system, to identify the number of visitors and understand how they use the site.
        </Typography>
      </Box> */}

      {/* <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Use of Information
        </Typography>
        <Typography paragraph>
          We use your personal information to provide and improve our services, including content personalization and data analytics. Our team may contact you to send updates about our services or to answer questions and comments.
        </Typography>
      </Box> */}

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Information Sharing
        </Typography>
        <Typography paragraph>
          We do not sell or share your personal information with third parties, except as necessary to provide our services or as required by law.
        </Typography>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Security
        </Typography>
        <Typography paragraph>
          We take reasonable steps to protect your personal information from loss, theft, or unauthorized use. However, we cannot guarantee that your information is completely secure.
        </Typography>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Updating Information
        </Typography>
        <Typography paragraph>
          To request an update or fix on a shortened URL you created, go to the contact form and enter the long URL and short URL you want to change.
        </Typography>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Privacy Rights
        </Typography>
        <Typography paragraph>
          ishowlink.site may send messages to advertise a product or service, and you may choose not to receive marketing messages by unsubscribing from our list.
        </Typography>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Cookies and Advertising Network
        </Typography>
        <Typography paragraph>
          We may use third-party advertising companies to display ads when you visit our site. These companies may use cookies, which are small text files placed on your device, and similar technologies to collect information for the purpose of displaying ads related to products and services of interest to you.
        </Typography>
        <Typography paragraph>
          ishowlink.site may display advertisements served by advertising companies, which use cookies to identify user preferences and browsing habits. Users can get more information about these cookies and privacy on advertising companies' websites.
        </Typography>
        <Typography paragraph>
          Web browsers accept cookies automatically, but if you prefer, you can modify the configuration of your browser to refuse cookies. However, this can affect how you interact with our site and other sites on the Internet.
        </Typography>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Privacy Policy Changes
        </Typography>
        <Typography paragraph>
          We reserve the right to modify this policy at any time. Any changes will be posted on this page and will take effect immediately.
        </Typography>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Other Important Information
        </Typography>
        <Typography paragraph>
          If there are any questions regarding privacy or if you need any further information, please contact our team.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
