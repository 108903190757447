import "./App.css";
// import Darkmode from "darkmode-js"
import Home from "./Home";
import {
  BrowserRouter as Router,
  Route,
  useParams,
  Routes,
} from "react-router-dom";
import RedirectPage from "./RedirectPage";
import TermsOfService from "./Terms";
import PrivacyPolicy from "./Privacy";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/:code" Component={RedirectPage} />
          <Route path="/terms-of-service" Component={TermsOfService}></Route>
          <Route path="/privacy-policy" Component={PrivacyPolicy} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
