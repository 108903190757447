import axios from "axios";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const baseURL = "https://server.ishowlink.site"
// const baseURL = "http://localhost:5000"
const RedirectPage = () => {
  const { code } = useParams(); // Extract the dynamic code from the URL
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAndRedirect = async () => {
      try {
        // Replace with your backend API endpoint
        const response = await fetch(`${baseURL}/getLink/${code}`);
        
        if (response.status === 200) {
          const data = await response.json();
          window.location.href = data.link; // Redirect to the retrieved link
          axios.post(`${baseURL}/addCount`, {
            linkId : code
          }).then((res) => console.log(res['data']))
          .catch(err => console.log(err))
        } else if (response.status === 404) {
          alert("Link not found");
          navigate("/"); // Navigate to home or error page
        } else {
          alert("An error occurred");
        }
      } catch (error) {
        console.error("Error fetching link:", error);
        // alert("Failed to fetch the link. Please try again later.");
      }
    };

    fetchAndRedirect();
  }, [code, navigate]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default RedirectPage;
